body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





/* chat.js style  */
.message-right {
  border-radius: 2rem;
  float: right;
}

.message-left {
  background-color: #e3e8ed;
  border-radius: 2rem;
  float: left;
}

.message-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.2 * var(--bs-gutter-x));
  margin-left: calc(-.2 * var(--bs-gutter-x));
}

.conversation-div-style {
  width: 100%;
  height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
}





/* popup.js style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.btn-close {
  background: transparent url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23fff"><path d="M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z"/></svg>') center/1em auto no-repeat;
}

.bg-dark-blue {
  background-color: #0c4a7c !important;
  color: white;
}

.box {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  max-width: 1111px;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  border: 1px solid #999;
  overflow: auto;
}

.modal-title {
  padding: 10px
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 1rem;
}

.modal-body {
  padding: 1rem;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  margin-left: -30px;
  margin-top: 6px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  font-size: 15px;
}


/* navbar.js style  */
.nav-body {
  background-color: white;
  position: relative;
  box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 10px 0px 10px;
}

/* @media (min-width: 990px){ */
.container {
  display: grid !important;
  width: auto;
}

.d-block {
  display: block !important;
}

.navbar-icon-test {
  /* width: auto !important; */
  background-size: contain;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.navbar-icon-live {
  /* width: auto !important; */
  background-size: contain;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.navbar-icon-demo  {
  /* width: auto !important; */
  background-size: contain;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.navbar-brand {
  margin-right: 0px;
}

.landing-icon {
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-image: linear-gradient(to top, rgb(245 246 252 / 0%), rgb(12 74 124 / 50%)), url("../src/icons/landingphoto.jpg");
  height: 300px;
}

/* } */

@media (max-width: 770px) {
  .user-role {
    display: none;
  }
}

@media (max-width: 992px) {
  .navbar {
    justify-content: space-around;
  }
}

@media (max-width: 576px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.dropdown-item.active {
  color: #fff !important;
}

.icon {
  margin-left: 5px;
}

.disabledDropdown {
  opacity: 0.7;
  pointer-events: none;
}

.navbar-icon-test {
  background-image: url("../src/icons/Here2Treat_logo_test.svg");
  height: 60px;
  width: 220px;
  margin-bottom: -10px;
}

.navbar-icon-demo {
  background-image: url("../src/icons/Here2Treat_logo_demo.svg");
  height: 60px;
  width: 220px;
  margin-bottom: -10px;
}

.footer-payment-icon {
  background-image: url("../src/icons/VisaMastercard.png");
  height: 40px;
  width: 130px;
  background-size: contain;
}

.navbar-icon-live {
  background-image: url("../src/icons/Here2Treat.svg");
  height: 60px;
  width: 220px;
  margin-bottom: -10px;
}

.nav-hidden {
  display: none;
}






/* login.js and register.js style */
* {
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif !important;
}

.page {
  color: #727272;
  /* margin: 10px 15px 10px 15px;
  justify-content: center;
  align-items: center;
  display: grid; */
}

.alert-message {
  color: red !important;
}

.btn-dark-blue {
  color: #fff !important;
  line-height: 2.3;
  background-color: #0c4a7c !important;
  border-color: #1663a2 !important;
}

.new-booking-btn {
  width: -moz-available;
}

.cover {
  background-color: white;
  position: relative;
  border-radius: 1em;
  box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
  flex-direction: column;
  justify-content: space-around;
  text-align: -moz-center;
  max-width: 1140px;
  padding: 20px;
  margin: 10px 0px 0px 0px;
}

.align-center {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.register-form-style {
  text-align: -webkit-center;
  width: -webkit-fill-available;
  width: -moz-available;
}

.alt-login {
  width: 80%;
  height: 3em;
  display: flex;
  justify-content: space-around;
}

.alt-remember {
  width: 80%;
  justify-content: space-between;
  display: flex;
}

.remember-forgot {
  width: 80%;
  display: flex;
}

.alt-remember-register {
  width: 80%;
  margin-bottom: 15px;
  justify-content: space-around;
  display: flex;
  color: red;
}

.alt-register-doctor {
  width: 80%;
  margin-bottom: 20px;
  justify-content: space-around;
  display: flex;
  color: red;
}

.alt-Terms {
  width: 80%;
  margin-top: 20px;
  justify-content: space-around;
  display: flex;
}

.flexDiv {
  justify-content: space-around;
  display: flex;
  width: 85%;
}

.flexDiv100 {
  justify-content: space-around;
  display: flex;

}

.alt-login div {
  width: 45%;
  height: 90%;
  border-radius: 0.25em;
  background-color: black;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.logo {
  background-image: url("../src/icons/login.svg");
  height: 260px;
  width: 260px;
}

.logo-da {
  background-image: url("../src/icons/logo-da.svg");
  height: 70px;
  width: 70px;
}

.logo-da-text {
  color: #727272;
  font-family: robotoSlab, Cambria, serif !important;
}

.row-80 {
  width: 80%;
  margin-bottom: 15px;
}

.row-80-h6 {
  width: 80%;
  margin-bottom: 5px;
}

.row-45 {
  width: 45%;
}

.row {
  border: none;
  background-color: rgb(229, 226, 226);
  height: 4em;
  width: 80%;
  border-radius: 0.25em;
  text-align: center;
  padding: 2em;
  margin-bottom: 40px;
}

.row-Register {
  /* border: none;
  background-color: rgb(229, 226, 226); */
  background-color: #fff;
  border: 1px solid #ced4da;
  height: 3em;
  width: 80%;
  border-radius: 0.25em;
  text-align: center;
  margin-bottom: 15px;
}

.row-Register-doctor {
  border: none;
  background-color: rgb(229, 226, 226);
  height: 2.5em;
  width: 80%;
  border-radius: 0.25em;
  text-align: center;
  margin-bottom: 20px;
}

.row-Register-doctor-textarea {
  border: none;
  background-color: rgb(229, 226, 226);
  width: 80%;
  border-radius: 0.25em;
  margin-bottom: 20px;
  padding: 10px;
}

.row-Register-without-margins {
  border: 1px solid #ced4da;
  background-color: #fff;
  height: 3em;
  width: 80%;
  border-radius: 0.25em;
  text-align: center;
}

.row-register-doctor {
  border: none;
  background-color: rgb(229, 226, 226);
  height: 2.5em;
  width: 80%;
  border-radius: 0.25em;
  text-align: center;
}

.login-btn {
  width: 40%;
  height: 3em;
  background-color: rgb(32, 177, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 0.25em;
  cursor: pointer;
}

.login-btn:hover {
  transform: scale(1.25);
}

.text {
  position: relative;
  bottom: -2em;
}

.hide {
  position: absolute;
  transform: scale(0);
}





/* dashboard.js style */
.coverD {
  background-color: white;
  margin: 10px 0px 0px 0px;
  max-width: 1140px;
  border-radius: 1em;
  box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  justify-content: space-around;
  text-align: -moz-center;
}

.breadcrumb-item-h2t {
  cursor: pointer;
}

.breadcrumb-item-h2t:hover {
  color: rgb(12, 74, 124);
  text-decoration: underline;
}

.cover-breadcrumb {
  background-color: white;
  margin: 10px 0px 0px 0px;
  max-width: 1140px;
  border-radius: 1em;
  box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
  display: flex;
  flex-direction: column;
  padding: 15px;
  justify-content: space-around;
}

.LoadingDiv {
  margin: 10px 15px 10px 15px;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  justify-content: space-around;
  display: none;
}

.bookingCard {
  width: -webkit-fill-available;
  width: -moz-available;
}

.pageD {
  width: 100%;
  color: #727272;
  /* justify-content: center;
  align-items: center;
  display: grid; */
}

/* .container {
  max-width: 1150px;
} */

.displayInfo {
  display: flex;
  flex-wrap: wrap;
}

.loader {
  height: 122vh !important;
}

.article {
  flex-basis: 50%;
}

.iconSize {
  font-size: xx-large;
}

.colorInfo {
  color: #0C4A7C !important;
}






/* booking.js style */
.searchCard {
  text-align: -webkit-center;
  width: -webkit-fill-available;
  width: -moz-available;
}

.cursor-center {
  grid-area: 1 / 3 !important;
  text-align: center !important;
}

.dot {
  height: 18px;
  width: 18px;
  margin: -3px 5px;
  border-radius: 50%;
  display: inline-block;
}

.search-form {
  width: -webkit-fill-available;
  width: -moz-available;
}

.row-Search {
  border: none;
  background-color: rgb(229, 226, 226);
  width: 80%;
  border-radius: 0.25em;
  text-align: center;
  height: 2.5rem;
  margin-bottom: 20px;
}

.row-Select {
  width: 80%;
  margin-bottom: 20px;
}

.error-message {
  margin-top: -15px;
  color: red;
  width: 70%;
  text-align: -webkit-right;
}

.chat-notification {
  background-color: #ff8c00 !important;
  color: #fff;
  padding-right: 0.5em;
  font-size: 12px;
  padding-left: 0.5em;
  position: absolute;
  margin-top: -3px;
  margin-left: -4%;
  border-radius: 10rem;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-dark-blue {
  --bs-btn-line-height: 2.3;
}

.medium-font-size {
  font-size: 16px !important;
}


/* Button classes */
.SmallButton {
  --bs-btn-line-height: 1.5;
  --bs-btn-font-size: 1rem;
}

.Button {
  --bs-btn-line-height: 2.3;
  --bs-btn-font-size: 1.1rem;
}

.LargeButton {
  --bs-btn-line-height: 2.7;
  --bs-btn-font-size: 1.4rem;
}


/* Text classes */
/* a {
  
}

p {
  
}

h1 {
  
}

h2 {
  
}

h3 {
  
}

h4 {
  
}

h5 {
  
}

h6 {
  
} */



/* Color classes */

.BaseBrandLive {
  color: darkblue !important;
  ;
}

.BaseBrandDev {
  color: red !important;
}

.BaseText {
  color: DarkGray !important;
}

.Inactive {
  color: gray !important;
}

.InactiveWarningLive {
  color: LightCoral !important;
}

.InactiveWarningDev {
  color: orange !important;
}

.WarningLive {
  color: red !important;
}

.WarningDev {
  color: yellow !important;
}

.Confirm {
  color: green !important;
}


.react-date-picker__wrapper {
  padding: 4px;
  border: none !important;
}

.datepicker-Style {
  width: 70%;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}



header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  padding: 0 10px;
  /* box-shadow: 0 0.188em 1.55em #9c9c9c; */
}

.header-div {
  box-shadow: 0 0.188em 1.55em #9c9c9c;
  text-align: -webkit-center;
  text-align: -moz-center;
}

header>* {
  width: 33.3vw;
}

header .header-logo {
  text-align: center;
  width: 220px;
}

.social-nav__header {
  display: flex;
  /* justify-content: flex-end; */
  flex-wrap: wrap;
}

.social-nav__aside {
  width: 80%;
  text-align: center;
  position: absolute;
  bottom: 2em;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 2em;
  border-top: 2px solid #fcfcfc;
}

.social-nav .fab,
.social-nav .far {
  font-size: 1.375em;
  color: #fcfcfc;
  margin: 0 6px;
}

.user-role {
  color: #727272 !important;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  font-size: 18px;
  width: 125px;
  cursor: pointer;
  ;
}

.active-language {
  background-color: darkgray;
  /* border-radius: 10px; */
  color: white !important;
}






@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");



.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}

.navbar {
  border-radius: 5px;
}

.sidebar {
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -100%;
  z-index: 999;
  transition: 0.5s;
}

.sidebar.active {
  top: 80px;
}

.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  /* visibility: visible; */
  z-index: 888;
}

.sd-body {
  padding: 15px;
  height: 265px;
  overflow-x: hidden;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, .1);
}

.row-header {
  width: 100%;
  background-color: white;
  padding: 1em;
}

.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}

.sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 1px 16px;
  color: #475f7b;
  /* background-color: #e5e8ec;
    border-radius: 4px; */
  cursor: pointer;
  text-decoration: none;
}
.language-button:focus{
  box-shadow: none !important;
}

.active-role {
  border-bottom: 7px solid #0c4a7c;
  background-color: #ededed;
}

.disabled {
  background-color: white !important;
  border-color: #bdbbbb !important;
  color: #797979!important;
}
.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  transition: opacity 500ms ease-in;
  opacity: 1;
  justify-content: center;
  color: white; /* Customize the text color */
  font-size: 1.3rem; /* Customize the font size */
}

.spinner {
  width: 45px;
  height: 45px;
  margin-bottom: 80px;
  margin-right: -63px;
  margin-left: 30px;
  border-radius: 50%;
  background: conic-gradient(
    #fff 0% 25%,
    #0c4a7c 25% 50%,
    #fff 50% 75%,
    #0c4a7c 75% 100%
  );
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.FAQClass {
  border: 1px solid #dbdbdb ; padding: 0px 10px 5px 10px;background-color: #fff;cursor: pointer;
}
.FAQClass:hover {
  background-color: #f5f4f4;
}
  .targetTextClass p{
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100%;
}


.targetTextClass div{
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100%;
}


.targetTextClass{
  width: 100%;
}

.targetTextClass ul li{
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100%;
}
.alert-primary {
    color: #004085;
    background-color: #fff;
    border-color: #b8daff;
}
.text-primary {
  color: #007bff !important;
}

.unselected-search-type {
  color:#0c4a7c !important;
  border-color: #0c4a7c !important; 
  background-color: #fff !important; 
}

div[class*="indicatorContainer"] {
  display: none !important;
}

span[class*="indicatorSeparator"] {
  display: none !important;
}